@use "/src/styles/variables" as *;

@media (max-width: 500px) {
  .notification .content-flex-row-between {
    flex-direction: column;
  }
}

.icon-warning {
  width: 2.5rem;
  height: 2.5rem;
}
