@use "../../styles/variables" as *;

$menu-width: 317px;
// Min screen
$menu-mobile-width: 280px;

.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  gap: 1rem;

  &__left-icon {
    flex-shrink: 1;
    width: 3rem;
    z-index: 1000;
  }

  .sidebar-menu {
    width: $menu-width;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 1.2rem;
    height: 100vh;
    justify-content: start;

    .line {
      width: 100%;
      height: 0.1rem;
    }

    .icon {
      width: 3rem;
      height: 3rem;

      .plus {
        padding: 10px;
      }
    }

    .sidebar-options {
      height: 80rem;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 2rem;
    }

    .invert-order-reverse {
      flex-direction: row-reverse;
      padding: 0rem 0rem 0rem 0rem;
      margin: 0 0 0 0;

      .icon {
        padding: 10px;
        margin-left: 20px;
        margin-right: 6px;
      }
    }

    .invert-order {
      flex-direction: row;
      padding: 0.25rem 0.5rem;
      margin: 0 20px 0 20px;
    }

    .sidebar-menu-item {
      user-select: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      font-size: 1rem;
      display: flex;
      justify-content: flex-start;
      max-height: 45px;
      border-radius: 5px;

      a {
        padding: 0;
      }

      span {
        &:nth-child(2) {
          padding-left: 1rem;
        }
      }

      &.bottom {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}

.navigation-bar {
  position: fixed;
  left: 0;
  z-index: 10;
  height: 6.938rem;
  width: 100%;

  button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px $black-shadow;
  }

  button::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
    justify-content: end;
  }

  & > div {
    height: 100%;
    position: relative;
  }

  .container {
    height: 100%;
  }

  .navigation {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .navigation-start {
      margin-left: $menu-width;
      justify-self: flex-start;
    }

    .navigation-end {
      justify-self: flex-end;

      .badge {
        font-size: 10px;
        background: $white;
        border-radius: 50%;
        padding: 3px;
        position: relative;
        right: -1rem;
        top: -1rem;
        height: 20px;
        width: 20px;
        font-weight: bold;
        border: solid 1px;
        text-align: center;
      }
    }

    .navigation-item {
      .hamburger {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--brand {
        font-weight: bolder;
        letter-spacing: 2px;
        font-size: 1.75rem;
      }
    }
  }
}

.container.is-fluid {
  padding-left: 0px;
  margin-left: 0px;
}

@media (max-width: 1023px) {
  .container.is-fluid {
    padding-left: 0px;
    margin-left: 0px;
  }
}

.sidebar-header {
  height: 9rem;

  .sidebar-header-item {
    width: 100%;

    &__brand {
      padding-top: 4rem;

      span {
        height: 64px;
        font-size: 3rem;
        display: block;
        margin: 0 auto;
      }
    }
  }

  p {
    margin: 0;
    text-align: start;
  }
}

.user-actions-dropdown {
  .dropdown {
    .button {
      font-size: 1rem;
      border: none;
      padding-left: 1rem;
      padding-right: 1rem;

      div {
        margin: 0 0.75rem;
      }
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
  }
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-left: 1rem solid white;
}

.sidebar-title {
  text-align: center;
}

.sidebar-subtitle {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.subcontainer-option {
  display: flex;
  justify-items: center;
  margin: 0 1rem 0 2rem;
}

.button-Content-Icon {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  cursor: pointer;

  :hover {
    background-color: transparent;
  }

  :focus {
    background-color: transparent;
  }
}

.div_icon {
  width: 40px;
  height: 40px;
  margin: 6px;
  background-color: $tertiary-palette-dark-mode;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-user-info {
  padding-left: 1rem;

  p {
    padding-top: 10px;
  }
}

.margin-Button {
  margin-top: 1rem;
}

.select-Item-Option {
  background-color: $accent-dark-mode;
}

.img-option-Navbar {
  width: 3.563rem;
  height: 3.563rem;
}

@media (max-width: 700px) {
  .navigation-bar {
    height: 5.813rem;
  }
}

@media (max-width: 600px) {
  .sidebar-header {
    height: 15rem;
  }
  .navigation-bar {
    height: 4.813rem;
  }
  .navbar-container {
    gap: 0px;

    &__left-icon {
      width: 0.5rem;
    }
  }
  .img-option-Navbar {
    width: 2rem;
    height: 2rem;
  }

  .img-return-Navbar {
    width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 300px) {
  .navbar-container {
    .sidebar-menu {
      width: $menu-mobile-width;
    }
  }
}
