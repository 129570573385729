@use "../../styles/variables" as *;

/*.iva-report-page-container {
  justify-content: center;
  //  display: flex;
}*/
.iva-report-seccion {
  box-shadow: 0px 1px 2px 0px #999;
  border-radius: 0.2rem;
  padding: 0.5rem;
  margin-top: 1.5rem;
  justify-content: center;
  overflow-wrap: break-word;
}
.iva-report-sub-seccion {
  margin: 2rem;
  border: 1px solid rgba(149, 149, 149, 0.512);
  padding: 1.5rem;
  overflow-wrap: break-word;
}
.iva-report-title-bill {
  justify-content: center;
  text-align: center;
  font-size: 2rem;
}
.iva-chart-container {
  height: 375px;
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
}

.iva-report-page-inputs {
  display: flex;
  justify-content: center;
  select {
    border: 0.1rem solid $primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 36px;
  }
}

.iva-report-sub-bill {
  display: flex;
}
.iva-report-page-year {
  width: 150px;
  margin-right: 1rem;
  background-color: white;
}
.iva-report-page-buttons {
  display: flex;
  justify-content: center;
}
.card-content-invoice-bill {
  background-color: white;
  border: 2px solid $secondary-palette-dark-mode;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
}
.iva-report-sub-seccion-bill {
  background-color: $blue-l;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 00.5rem;
}
.button-delete {
  background-color: $secondary-palette-dark-mode;
  color: #ffff;
  position: absolute;
  right: 2rem;
}

.iva-report-taxes {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.accordion {
  max-width: 600px;
  margin: 1rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  border-start-start-radius: 0.5rem;
  border-start-end-radius: 0.5rem;
}

.accordion-title:hover {
  background-color: rgba(248, 244, 244, 0.973);
}

.accordion-title,
.accordion-content {
  padding: 0.5rem;
}

.accordion-content {
  background-color: white;
  border-end-start-radius: 0.5rem;
  border-end-end-radius: 0.5rem;
  margin-bottom: 1rem;
}
