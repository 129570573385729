@use "../styles/variables" as *;

//LIGHT THEME
.light_modal {
  .input {
    background: $principal-light-input;
    border-bottom: 2px solid $border-bottom-blue;

    &:hover,
    &:focus {
      background: $input-light-focus;
      border-bottom: 2px solid $border-light-focus;
    }

    &:active {
      border-bottom: 2px solid rgba(56, 133, 191, 1);
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $secondary-palette-dark-mode;
    }
  }

  .second-selected {
    background: $principal-light-input;
    border-bottom: 2px solid $border-bottom-blue;
    color: #ffffff;

    &:hover,
    &:focus {
      background: $input-light-focus;
      border-bottom: 2px solid $border-light-focus;
    }

    &:active {
      border-bottom: 2px solid $input-light-active;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $secondary-palette-dark-mode;
    }
  }

  .second-input {
    background: $principal-light-input;
    border-bottom: 2px solid $border-bottom-blue;

    &:hover,
    &:focus {
      background: $input-light-focus;
      border-bottom: 2px solid $border-light-focus;
    }

    &:active {
      border-bottom: 2px solid $input-light-active;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $secondary-palette-dark-mode;
    }
  }

  .second-Button {
    &-transparent {
      border: 1px solid $border-black;
      background: rgba(255, 255, 255, 0);
    }

    &-blue {
      background: $accent-dark-mode;
      color: white;
    }

    &-darkBlue {
      border: 1px solid $border-black;
      background: rgba(255, 255, 255, 0);
    }

    &-lightBlue {
      background: $accent-light-blue;
    }
    .icon-button {
      filter: invert(100%);
    }
    .icon-color {
      filter: invert(100%);
    }
  }

  //OPTION SELECT
  .css-166bipr-Input {
    color: #000000 !important;
  }

  .option-content-left {
    color: #000000 !important;
  }

  .css-aiytvw-singleValue {
    color: #000000 !important;
  }

  .icon-select-input {
    filter: invert(40%);
  }
  .search-icon {
    filter: invert(100%);
  }
}

//DARK THEME
.dark_modal {
  .input {
    background-color: $secondary-palette-dark-mode;
    color: $white-base;
    border-bottom: 2px solid $white-base;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:active {
      border-bottom: 2px solid rgba(57, 110, 156, 1);
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $placeholder-dark;
    }
  }

  .second-selected {
    background-color: $secondary-palette-dark-mode;
    color: $white-base !important;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:active {
      border-bottom: 2px solid $accent-dark-mode;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $placeholder-dark;
    }
  }

  .second-input {
    background-color: $secondary-palette-dark-mode;
    color: $white-base;
    border-bottom: 2px solid $white-base;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:active {
      border-bottom: 2px solid $accent-dark-mode;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $placeholder-dark;
    }
  }

  .second-Button {
    &-transparent {
      border: 1px solid $white-base;
      background: "transparent";
    }

    &-blue {
      background: $tertiary-palette-dark-mode;
    }

    &-darkBlue {
      background: rgba(9, 25, 40, 1);
    }

    &-lightBlue {
      background: $accent-dark-mode;
    }
  }

  //OPTION SELECT
  .css-166bipr-Input {
    color: $white-base !important;
  }

  .css-qbdosj-Input {
    color: $white-base !important;
  }

  .css-1l1zhpc-menu {
    background: $black-palette !important;
  }

  .icon-select-input {
    filter: invert(0%);
  }

  .css-aiytvw-singleValue {
    color: #dae8f0 !important;
  }
  .icon-button {
    filter: invert(10%);
  }
  .icon-color {
    filter: invert(10%);
  }
  .search-icon {
    filter: invert(10%);
  }
}
