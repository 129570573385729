@use "../../styles/variables" as *;

.main-register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(6, 14, 27, 0.5);
  width: 90vw;
  max-width: 460px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}
.container-accept-terms {
  display: flex;
  margin: 1rem 0 1rem 0;
}
.input-password {
  display: flex;
  align-items: center;
  width: 100%;
  .input {
    width: 100%;
  }
  .icon {
    cursor: pointer;
    position: absolute;
    right: 25px;
  }
}

@media screen and (min-width: 400px) {
  .main-register {
    padding: 3rem 4rem 2rem 4rem;
  }
}

@media screen and (max-width: 399px) {
  .main-register {
    padding: 3rem 1rem 1rem 1rem;
  }
}
