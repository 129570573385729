.roles {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 50%;
}

.permissions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.submodulos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gridd {
  display: flex;
  column-gap: 100px;
}

.card-role {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  color: #0774d9;
  border-radius: 5px;
  min-width: 250px;
  min-height: 60px;
  margin-top: 1rem;
  &:hover {
    cursor: pointer;
    border: 1px solid #01234c;
    color: #01234c;
  }
}
