@use "../../../../../styles/variables" as *;
.tax-container {
  justify-content: center;
  align-items: center;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.container-button {
  justify-content: flex-end;
  display: flex;
}
