.box-option {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  -webkit-flex-direction: row;
}

.contain-edit {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  justify-items: center;
  justify-content: center;
}

.box-select {
  margin-top: 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.select-width {
  max-width: 140px;
}

.edit-width {
  max-width: 72px;
}

.add-width {
  max-width: 132px;
}

.inventories-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .box-option {
    flex-direction: column;
  }

  .select-width {
    max-width: 100%;
  }

  .contain-edit {
    justify-content: end;
  }

  .inventories-buttons {
    justify-content: end;
  }
}
