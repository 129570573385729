@use "../../styles/variables" as *;

// Class Card Upload Documents XML

.input-file {
  display: none;
}

// .dropzone {
//   background: $white-ter;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 100px;
//   padding: 20px;
//   cursor: pointer;
//   height: 200px;
//   width: 100%;
//   border: 2px dashed $primary;
//   outline: none;
// }

.dropzoneReject {
  border: 2px dashed rgb(179, 31, 31);
}

.dropzoneAccept {
  border: 2px dashed rgb(49, 179, 49);
}

.file-select {
  background-color: $secondary-palette-dark-mode;
  border: 0.1rem solid $secondary-palette-dark-mode;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  padding: 1rem;
}

.card-title {
  font-size: 2rem;
}

.button-select {
  margin: 1rem;
}

.btn-animate {
  animation: moveInBottom 1s ease-out;
  animation-fill-mode: backwards;
}

.body-card {
  height: 75%;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.card-header-button {
  margin-top: 1rem;
  text-align: right;
  display: flex;
  justify-content: center;
}

.card-checkbox {
  margin-left: 5%;
}

.content-invoice {
  display: flex;
  justify-content: space-between;

  button:hover {
    transform: translateY(-3px);
  }
}
