@use "../../styles/variables" as *;

.notifications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.notifications-container > * {
  margin: 10px;
}
