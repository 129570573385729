@use "../../styles/variables" as *;

//LIGHT MODE //
.theme {
  color: $black-palette;

  // INPUTS
  .input {
    background: $principal-light-input;
    border-bottom: 2px solid $border-bottom-blue;

    &:hover,
    &:focus {
      background: $input-light-focus;
      border-bottom: 2px solid $border-light-focus;
    }

    &:active {
      border-bottom: 2px solid rgba(56, 133, 191, 1);
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $secondary-palette-dark-mode;
    }
  }

  .defaultInput {
    color: $white-palette;
    background-color: $secondary-palette-dark-mode;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: rgb(255, 255, 255);
      -webkit-box-shadow: 0 0 0px 1000px $secondary-palette-dark-mode inset;
      border-radius: 5px;
      border: 1px solid $border-light-accent;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      background: transparent;
    }
  }

  .second-input {
    background: $principal-light-input;
    border-bottom: 2px solid $border-bottom-blue;

    &:hover,
    &:focus {
      background: $input-light-focus;
      border-bottom: 2px solid $border-light-focus;
    }

    &:active {
      border-bottom: 2px solid $input-light-active;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $secondary-palette-dark-mode;
    }
  }

  //SEARCH ICON
  .input-container .search-icon {
    filter: invert(100%);
  }

  //BUTTONS

  .second-Button {
    background-color: $border-bottom-blue;
  }

  //Cards
  .principal-card {
    background: rgba(200, 207, 214, 1);
  }

  .second-card {
    background: rgba(200, 207, 214, 1);
  }

  // //LETTER
  p,
  strong {
    color: $primary-palette-dark-mode;
  }

  .defaultDark {
    color: rgb(255, 255, 255);
  }

  .icon-button {
    filter: invert(100%);
  }

  .icon-color {
    filter: invert(100%);
  }

  .awesomeIcon-color {
    color: black;
  }

  .button-awesomeIcon {
    background-color: #f6f9fe;
    border: 1px solid #123b64;
  }

  .sidebar-menu {
    background: $secondary-palette-dark-mode;
    color: $white-base;

    .sidebar-menu-item {
      &:hover {
        background-color: $hover-button;
      }

      &.is-current {
        background-color: $hover-button;
      }

      &.is-active {
        background-color: $Active-button;
      }
    }

    .line {
      color: rgba(62, 83, 103, 1);
    }
  }

  .dropzone {
    background: #123b64;
  }

  .CSVLink {
    color: #01234c !important;
  }

  .css-aiytvw-singleValue {
    color: #000 !important;
  }
  .css-166bipr-Input {
    color: #000000 !important;
  }
  .css-8geu8c-MenuPortal {
    color: #000000 !important;
  }
}

// DARK MODE //
.theme-dark {
  color: $white-palette;

  // INPUTS
  .input {
    background-color: $secondary-palette-dark-mode;
    color: $white-base;
    border-bottom: 2px solid $white-base;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:active {
      border-bottom: 2px solid rgba(57, 110, 156, 1);
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $placeholder-dark;
    }
  }

  .defaultInput {
    color: $white-palette;
    background-color: $secondary-palette-dark-mode;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: rgb(255, 255, 255);
      -webkit-box-shadow: 0 0 0px 1000px $secondary-palette-dark-mode inset;
      border-radius: 5px;
      border: 1px solid $border-light-accent;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      background: transparent;
    }
  }

  .second-input {
    background-color: $secondary-palette-dark-mode;
    color: $white-base;
    border-bottom: 2px solid $white-base;

    &:hover,
    &:focus {
      background: $input-dark-focus;
      border-bottom: 2px solid $border-input-focus;
    }

    &:active {
      border-bottom: 2px solid $accent-dark-mode;
    }

    &:disabled {
      border-bottom: 2px $disabled-button-second;
    }

    &::placeholder {
      color: $placeholder-dark;
    }
  }

  //SEARCH ICON
  .input-container .search-icon {
    filter: invert(10%);
  }

  //BUTTONS

  .second-Button {
    background-color: $tertiary-palette-dark-mode;

    &:hover,
    &:focus {
      background: $hover-component;
    }

    &:active {
      background: $Active-component;
    }

    &:disabled {
      background: $disabled-button-second;
    }
  }

  //Cards
  .principal-card {
    background: $secondary-palette-dark-mode;
  }

  .second-card {
    background: $secondary-palette-dark-mode;
  }

  //LETTER
  p,
  strong {
    color: rgb(255, 255, 255);
  }

  .icon-button {
    filter: invert(10%);
  }

  .icon-color {
    filter: invert(10%);
  }

  .awesomeIcon-color {
    color: white;
  }

  .button-awesomeIcon {
    background-color: #030710 !important;
    border: 1px solid #173859;
  }

  .sidebar-menu {
    background: $secondary-palette-dark-mode;
    color: $white-base;

    .sidebar-menu-item {
      &:hover {
        background-color: $hover-button;
      }

      &.is-current {
        background-color: $hover-button;
      }

      &.is-active {
        background-color: $Active-button;
      }
    }

    .line {
      color: rgba(62, 83, 103, 1);
    }
  }

  .dropzone {
    background: #091928;
  }

  .CSVLink {
    color: #bff6f4 !important;
  }
  .css-aiytvw-singleValue {
    color: #ffff !important;
  }
}
