.main-modify-product {
  width: 476.5px;
  height: 630px;
  padding: 46px 48px 46px 48px;
  overflow: auto;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-button-width {
    width: 111px;
  }
}

@media only screen and (max-width: 500px) {
  .main-modify-product {
    width: 400px;
    padding: 10px 20px 10px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .main-modify-product {
    width: 270px;
    padding: 5px 5px 5px 5px;
  }
}
