@use "../../styles/variables" as variables;

.places-button {
  background-color: rgba(217, 217, 217, 1) !important;
}

.company {
  background-color: variables.$primary-palette-light-mode;
  border-color: rgba(38, 101, 155, 1);

  &.dark-mode {
    background-color: variables.$primary-palette-dark-mode;
    border-color: rgba(57, 110, 156, 1);
  }
}

.place-card {
  background-color: variables.$primary-card-light !important;

  &.dark-mode {
    background-color: variables.$secondary-palette-dark-mode !important;
  }
}

.full-width-input-search {
  width: 100%;
}
