@use "../../styles/variables" as *;

.product-img {
  width: 60px;
  margin: 0.2rem;
}

.selected-place {
  background-color: darken(#f5f9ff, 10%);
}

.invetories-container {
  margin: 0 90px;
  &.modal-mode {
    margin: 25px;
  }
}

.white-text {
  color: white !important;
}

.input-container {
  width: 100%;
}

.modal-search-input {
  background: $principal-light-input;
  width: 100%;

  &:hover,
  &:focus {
    background: $input-light-focus;
    border-bottom: 2px solid $border-light-focus;
  }

  &:active {
    border-bottom: 2px solid rgba(56, 133, 191, 1);
  }

  &:disabled {
    border-bottom: 2px $disabled-button-second;
  }

  &::placeholder {
    color: $secondary-palette-dark-mode;
  }
}

.modal-search-input-dark {
  background-color: $secondary-palette-dark-mode;
  color: $white-base;
  border-bottom: none;
  width: 100%;

  &:hover,
  &:focus {
    background: $input-dark-focus;
    border-bottom: 2px solid $border-input-focus;
  }

  &:active {
    border-bottom: 2px solid $accent-dark-mode;
  }

  &:disabled {
    border-bottom: 2px $disabled-button-second;
  }

  &::placeholder {
    color: $placeholder-dark;
  }
}
