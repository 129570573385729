@use "../../styles/variables" as *;

.divider {
  background-color: $primary;
  width: 100%;
  height: 2px;
  margin: 10px 0px;

  &--transparent {
    background-color: transparent;
  }
  &--shadow {
    background-color: lighten($primary, 80%);
  }
}
