@use "../../styles/variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");

$transition-time: 250ms;

a:hover {
  color: white;
}

p {
  margin: 0px;
}

.home-card {
  background-color: $secondary-palette-dark-mode;
  height: 127px;
  padding: 30px;

  &.home-card-item {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  &.home-card-item-clickable:hover {
    transition: $transition-time;
    background-color: #164064;
    cursor: pointer;
  }
  &.home-card-item-clickable:active {
    background-color: #145a90;
    transition: $transition-time;
  }
  &.home-card-item-clickable:disabled {
    background-color: #334552;
    transition: $transition-time;
  }

  &.home-card-icon {
    height: 75px;
  }
}
