.CardProductConteinerButtons {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.CardProductBody {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
}

.card-Product {
  display: flex;
  justify-content: start;
  justify-items: center;
  gap: 1rem;
  text-align: center;
  width: 100%;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content-buttons {
  display: flex;
  justify-content: end;
  gap: 1rem;
}

.button-modifications {
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
}

.icon-button {
  width: 1.25rem;
  height: 1.25rem;
}

.card-image {
  width: 4.4rem;
  height: 4.4rem;
}
