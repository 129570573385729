@use "../../styles/variables" as *;

.place-selector {
  align-self: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $accent-dark-mode;
  gap: 1rem;
  h1 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
  }

  &:hover {
    background-color: $hover-button;
  }

  &__image {
    aspect-ratio: 1/1;
  }

  &__modal-container {
    max-height: 600px;
  }
}
