@use "../../styles/variables" as *;

.content-login {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(6, 14, 27, 0.5);
  width: 90vw;
  max-width: 460px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.button-forgot {
  border: none;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 0;
}

.button__startAuth {
  display: flex;
  justify-content: center;
  background-color: $secondary-palette-dark-mode;
  padding: 0.125rem;
  border-radius: 0.313rem;
  width: 100%;
  &:hover {
    color: $white-palette;
    background: $hover-home;
  }
}

.button__width__Full {
  width: 100%;
  min-width: 90px;
}

.content-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  color: #ffffff;
  p {
    margin: 0;
  }
}

.line {
  margin: 10px;
  width: 100%;
  border: none;
  border-top: 1px solid #ffffff;
}

@media screen and (min-width: 400px) {
  .content-login {
    padding: 3rem 4rem 2rem 4rem;
  }
}

@media screen and (max-width: 399px) {
  .content-login {
    padding: 3rem 1rem 1rem 1rem;
  }
}
