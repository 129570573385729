@use "../../styles/variables" as *;

.custom-label {
  display: flex;
  justify-content: space-around;
  padding: 12px;
}

.d104-configuration-container {
  padding-bottom: 1rem;
  display: inline-block;
}

.secondary-content {
  &:hover {
    background-color: #e8f1ff;
    cursor: pointer;
  }
}

.time-picker {
  outline: none;
  width: 160px;
  height: 40px;
  font-size: 20px;
  text-transform: capitalize;
  border-color: black;
  background-color: white;
  transition-duration: 80ms;
}
.time-picker:hover {
  border: solid 1px $grey-light;
}

.custom-border {
  div {
    border-radius: 0.6rem;
  }
}

.D104-configuration-input {
  outline: none;
  width: 300px;
  height: 40px;
  font-size: 20px;
  border-radius: 0.6rem;
}

.D104-configuration-input-execution-time {
  outline: none;
  width: 160px;
  height: 40px;
  font-size: 20px;
  border-radius: 0.6rem;
}

.D104-configuration-input-user {
  outline: none;
  width: 400px;
  height: 40px;
  font-size: 20px;
  border-radius: 0.6rem;
}

.D104-configuration-input-TIV {
  outline: none;
  width: 50px;
  height: 40px;
  font-size: 20px;
  border-radius: 0.6rem;
  text-transform: uppercase;
}

.margin-div {
  margin-left: auto;
  margin-top: 40;
}

.custom-div {
  display: grid;
  padding: 10px;
  margin-left: 40px;
}

.buttons-D104-configuration {
  display: flex;
  justify-content: space-around;
}

.order-TIV {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  justify-content: space-around;
}

.custom-card {
  border-radius: 0.2rem;
  margin-left: 10px;
  border-color: black;
}
