@use "../../styles/variables" as *;

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-container {
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 10000;
  background: transparent;
  display: flex;
  height: 70vh;
  width: 100%;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &.small {
    height: auto;
  }
}

.loading-label {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  font-size: x-large;
}

.loading-animation {
  border: 15px solid #ccc;
  border-top-color: $secondary-palette-dark-mode;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
