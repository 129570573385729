@use "../../../../styles/variables" as *;

.inventory-container {
  border-radius: 5px;
  padding: 20px;
  background-color: $secondary-palette-dark-mode;
  color: $principal-light-input;
  cursor: pointer;
}

.inventory-image {
  width: 53px;
  aspect-ratio: square;
}
