@use "../../styles/variables" as *;

.custom-label {
  display: flex;
  justify-content: space-around;
  padding: 12px;
}

.scheduled-bill-container {
  padding-bottom: 1rem;
  display: inline-block;
}

.secondary-content {
  &:hover {
    background-color: #e8f1ff;
    cursor: pointer;
  }
}

.date-picker {
  outline: none;
  width: 200px;
  height: 40px;
  font-size: 20px;
  text-transform: capitalize;
  border: solid 1px grey;
  background-color: white;
  border-radius: 0.6rem;
  transition-duration: 80ms;
}

.time-picker {
  outline: none;
  width: 200px;
  height: 40px;
  font-size: 20px;
  text-transform: capitalize;
  border-color: black;
  background-color: white;
  transition-duration: 80ms;
}
.time-picker:hover {
  border: solid 1px $grey-light;
}

.custom-border {
  div {
    border-radius: 0.6rem;
  }
}
