.DSForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px 30px;
  flex-wrap: wrap;
  &__select {
    min-width: 200px;
  }
  &__generic-input {
    min-width: 300px;
    flex-grow: 1;
  }
  &__search-button {
    width: min-content;
    align-self: center;
    width: 300px;
  }
  &__date {
    border: 0.1rem solid #737d88;
    height: 36px;
    width: min-content;
    text-align: center;
    width: 300px;
    border-radius: 3px;
    padding: 5px 9px;
  }
}

.ocultar {
  display: none;
}

.spacing {
  padding: 5px;
}
