.actions-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px;

  .actions-button {
    margin-top: 0.5rem;
    min-height: 50px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  .actions-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .actions-button {
      margin-top: 0.5rem;
      min-height: 50px;
      padding: 10px 20px;
      font-size: 18px !important;
    }
  }
}
