@use "../../../../styles/variables" as *;

.modal-mode-card-light-mode-color {
  background-color: $border-bottom-blue !important;
}

.modal-mode-card-dark-mode-color {
  background-color: $secondary-palette-dark-mode !important;
}

.modal-mode-image {
  width: 41px;
  aspect-ratio: square;
}
