$margin-unit: 1;

@mixin customAxisMargin($vertical_size, $horizontal_size) {
  margin: if($vertical_size== "auto", auto, $vertical_size * $margin-unit + rem)
    if($horizontal_size== "auto", auto, $horizontal_size * $margin-unit + rem);
}
@mixin customSideProperty($property, $side, $margin) {
  #{$property}-#{$side}: $margin + rem;
}

$sides: (
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@for $i from 1 through 5 {
  @each $sideChar, $sideName in $sides {
    .m#{$sideChar}-#{$i} {
      @include customSideProperty("margin", $sideName, $i);
    }
    .p#{$sideChar}-#{$i} {
      @include customSideProperty("padding", $sideName, $i);
    }
  }
  .mv-#{$i} {
    @include customAxisMargin($i, 0);
  }
  .mh-#{$i} {
    @include customAxisMargin(0, $i);
  }
  .mva-#{$i} {
    @include customAxisMargin($i, "auto");
  }
  .mha-#{$i} {
    @include customAxisMargin("auto", $i);
  }
  .m-#{$i} {
    @include customAxisMargin($i, $i);
  }
  .gap-#{$i} {
    gap: $i + rem;
  }
  .flex-grow-#{$i} {
    flex-grow: $i;
  }
}
