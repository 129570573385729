.table-item {
  text-align: center;
  color: black;
}

.table-head {
  text-align: center !important;
  color: white;
}

.check {
  height: 25px;
  padding: 0em 2em !important;
}

.container-table-rol {
  overflow-y: auto;
}

.edit-button {
  background: transparent;
  color: #ff3860;
  border: solid 0.5px;
}
