@use "../../styles/variables" as *;

.container-button {
  display: flex;
  flex-direction: column;

  .input-container {
    position: relative;
  }

  .icon {
    position: absolute;
    right: 1rem;
    bottom: 0.3rem;
    transform: translateY(-20%);
    cursor: pointer;
    z-index: 5;
  }

  p {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 300;
    line-height: 13.41px;
    text-align: left;
    color: rgba(57, 110, 156, 1);
  }
}

.icon_calendar {
  pointer-events: none;
}
