@use "../../styles/variables" as *;

.icon-client {
  width: 3.75rem;
  height: 3rem;
}

.information-container {
  display: flex;
  align-items: end;
  gap: 1rem;
  cursor: pointer;
}

.groups-header {
  display: flex;
  justify-content: end;

  button {
    min-width: 145px;
  }
}
