.table-item {
  text-align: center !important;
  color: black;
}

.table-head {
  text-align: center !important;
  color: white;
}

.check {
  justify-content: center;
}

.no-border-bottom {
  border-bottom: 0px solid !important;
}

.new-user-select {
  .select {
    margin: 10px;
  }
}

.edit-button {
  background: transparent;
  color: #ff3860;
  border: solid 0.5px;
}
