@use "variables" as variables;
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

@import "bulma";
@import "rbx";

$menu-width: 260px;

#root {
  height: inherit;
}

html,
body {
  width: 100%;
  height: auto;
  min-width: 260px;
}

.principal-container {
  display: flex;
  justify-content: center;
}

// ELIMINAR DESPUES
.content-right {
  justify-content: flex-end;
  text-align: center;
  width: 30%;
}

//
.content-flex-end {
  justify-content: flex-end;
  display: flex;
  gap: 1rem;
}

.content-flex-center {
  flex-direction: column;
  display: flex;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.break-text {
  word-break: break-all;
}

.evenly {
  @extend .flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
}

.container-header {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.middle {
  justify-content: center;
  align-items: center;
}

.align-left {
  float: inline-end;
  position: absolute;
  left: 0rem;
  top: 0rem;
  color: white;
}

.align-right {
  right: 0;
  top: 0;
  position: absolute;
}

.card-total-width {
  width: 95%;
}

.container-padding {
  margin: 1rem;
  padding-top: 1rem;
}

img {
  height: 100%;
  width: 100%;
}

table {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-content {
  background-color: variables.$grey-font;
  border-radius: 7px;
}

//ELIMINAR DESPUES
.card-content {
  background-color: #f5f9ff;
  border: 0.5px solid variables.$secondary-palette-dark-mode;
  border-radius: 7px;
  color: variables.$secondary-palette-dark-mode;
  padding: 0.5rem;
  margin-top: 1rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;
  max-height: 100%;
}

.card-summary {
  background-color: #f5f9ff;
  border: 0.5px solid variables.$secondary-palette-dark-mode;
  border-radius: 7px;
  color: variables.$secondary-palette-dark-mode;
  padding: 0;
  margin-top: 1rem;
  border-start-start-radius: 0.2rem;
  border-start-end-radius: 0.2rem;
  width: 100%;

  .card-secondary-content {
    background-color: variables.$primary;
    border: 0.5px solid variables.$secondary-palette-dark-mode;
    border-radius: 7px;
    color: variables.$secondary-palette-dark-mode;
    padding: 0.3rem;
    width: 100%;
    margin: 0;
    border-start-start-radius: 0.2rem;
    border-start-end-radius: 0.2rem;

    span,
    h4,
    h3 {
      color: white;
    }
  }
}
//
.profilepic {
  position: relative;
  width: 125px;
  height: 125px;
  overflow: hidden;
  background-color: #111;
  border-radius: 7px;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  height: 25%;
  background: rgba(245, 249, 255, 0.6);
  border-bottom: 7px;
}

.profilepic__content:hover {
  opacity: 1;
}

.profilepic__icon {
  color: white;
  padding-bottom: 8px;
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}

.card-products {
  background-color: variables.$primary;
  border: 0.5px solid variables.$secondary-palette-dark-mode;
  border-radius: 7px;
  color: variables.$secondary-palette-dark-mode;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;

  span,
  h4,
  h3 {
    color: white;
  }
}

.tag:not(body).is-primary {
  background-color: variables.$secondary-palette-dark-mode;
}

//ELIMINAR DESPUES
.button {
  background-color: transparent;
  color: variables.$white-palette;
  border: variables.$white-palette 0.1rem solid;
}

.card-button:hover {
  color: #a9a9a9;
}

.card-button:active {
  color: #a9a9a9;
}

.card-button:focus {
  color: #a9a9a9;
}

//CONTAINERS DIV
.content-left {
  justify-content: flex-start;
  text-align: flex-start;
  width: 70%;
}

.content-center {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 1rem;
}

//INPUTS

.input {
  box-shadow: none;
  height: 48px;
  padding: 15px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
}

.second-input {
  box-shadow: none;
  height: 48px;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;

  .menuSelect {
    height: 3rem;
    overflow-y: auto;
  }
}

.input-transparent {
  background: "transparent";
  width: 100%;
  height: 46px;
  border: none;
  outline: none;
}

.input-transparent:focus {
  border: none;
  outline: none;
}

//MUI SELECT
.icon-select-input {
  position: absolute;
  right: 0.7rem;
  bottom: 0.3rem;
  transform: translateY(-20%);
  cursor: pointer;
  z-index: 50;
  pointer-events: none;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  backdrop-filter: blur(15px);
  background-color: transparent !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  height: 7rem;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 23rem;
  padding: 0;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  background-color: transparent;
  display: contents;
}

.css-27vf6x-control {
  border-color: none;
  box-shadow: none;
}

//

.second-selected {
  width: 100%;
  box-shadow: none;
  height: 45px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
}

.input:focus {
  box-shadow: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

.input:active {
  box-shadow: none;
  border-top: none;
  border-right: none;
  border-left: none;
}

.items-centered {
  display: flex;
  align-items: center;
}

.phone-input {
  border: 0.1rem solid variables.$primary;
  display: flex;
  border-radius: 4px;

  .input {
    border: none;
    box-shadow: none;
    height: initial;
  }

  .select-phone {
    width: 150px;
    border: none;
    border-right: 0.1rem solid variables.$primary;
  }

  &-icon {
    @extend .items-centered;
  }

  &-flag {
    height: 20px;
    width: auto;
    margin-right: 10px;
  }
}

a {
  text-align: center;
}

.input::placeholder {
  color: $black-ter;
}

.primary-title {
  background-color: variables.$primary;
  color: variables.$white-palette;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

// OVERFLOW
::-webkit-scrollbar-thumb {
  background-color: "transparent";
}

::-webkit-scrollbar-track {
  background-color: "transparent";
}

::-webkit-scrollbar {
  width: 3px;
}

//CARD BUTTONS
.card-button:hover {
  color: #a9a9a9;
}

.card-button:active {
  color: #a9a9a9;
}

.card-button:focus {
  color: #a9a9a9;
}

.secondary-button {
  background: none;
  border: none;
  cursor: pointer;
}

.edit-icon {
  top: 0.5rem;
  color: #01234c;
  position: absolute;
  right: 3rem;
}

//ELIMINAR DESPUES
button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

button::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.button.is-secondary {
  background-color: transparent;
  border: 2px solid variables.$secondary-palette-dark-mode;
  color: variables.$secondary-palette-dark-mode;
  margin-right: 1rem;
  cursor: pointer;

  &.m-0 {
    margin-right: 0;
  }
}

.button.is-primary {
  background-color: #01234c;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.button.is-primary:hover {
  background-color: #002d64;
}

.button.is-primary:disabled {
  background-color: #01234c;
}

.button.is-primary:focus {
  background-color: #01234c;
}

.button.is-primary.is-outlined {
  border-color: variables.$primary;
  color: variables.$primary;
}

.button.is-primary.is-outlined:hover {
  background-color: #002d64;
  border-color: #002d64;
  color: variables.$white-palette;
}

.button.is-primary.is-outlined:disabled {
  border-color: #01234c;
  color: #01234c;
}

.button.is-primary.is-outlined:focus {
  background-color: #01234c;
}

button.is-secondary::after {
  background-color: #fff;
}

//

//FONT
button,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p,
select,
Label,
table {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 0.8rem;
}

p {
  font: size 0.5rem;
}

//
.title-center {
  justify-content: center;
  display: flex;
  font-size: 1.2em;
}

.has-text-white {
  color: variables.$white-palette !important;
}

::-webkit-scrollbar {
  width: 8px;
}

.main-container {
  margin-left: 67px;
  transition: margin 0.3s linear;
  font-family: "PT Sans", sans-serif;

  .main-page-container {
    transition: padding 0.3s linear;
  }

  &.open {
    margin-left: 10px;

    nav {
      transform: translate3d(0, 0, 0);
    }
  }
}

.main-page-container {
  padding: calc(110px + 8px) 70px 40px 70px;
  min-height: 100vh;
  width: 100%;
}

//ICON 30 x 30 px
.icon-size-card {
  width: 1.875rem;
  height: 1.875rem;
}

@media screen and (min-width: 1024px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.8rem;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 721px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.8rem;
    padding: 1rem;
    grid-row-gap: 0.8rem;
  }
}

@media screen and (min-width: 769px) {
  main.main-container {
    margin-left: 57px;
  }
}

@media screen and (max-width: 900px) {
  .main-page-container {
    font-family: "PT Sans", sans-serif;

    &.open {
      opacity: 0.5;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #059e42;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Para 941px a 1024px */
@media only screen and (min-width: 941px) and (max-width: 1024px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
  }
}

/* Para 801px a 940px */
@media only screen and (min-width: 801px) and (max-width: 940px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 722px a 800px */
@media screen and (max-width: 800px) and (min-width: 722px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 621px a 721px */
@media screen and (max-width: 721px) and (min-width: 621px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 461px a 620px */
@media only screen and (max-width: 620px) and (min-width: 461px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 361 a 460px */
@media only screen and (max-width: 460px) and (min-width: 361px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.7rem;
  }
}

/* Para 5px a 360px */
@media only screen and (max-width: 360px) and (min-width: 5px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.7rem;
    padding: 0.7rem;
    grid-row-gap: 0.7rem;
    justify-content: center;
  }

  .grid-small {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0.5rem;
  }

  .grid-top {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: auto;
    height: auto;
    white-space: normal;
    word-break: keep-all;
    text-align: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//ELIMINAR DESPUES
.form-inputs {
  select {
    border: 0.1rem solid variables.$primary;
    background-color: unset;
    border-bottom: 1px solid;
    font-size: 0.9rem;
    border-radius: 0.3rem;
    height: 37px;
  }

  border-radius: 0.5rem;
  text-align: center;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 5rem;
  margin-left: 5rem;
  justify-content: center;
}

.is-text-breaker {
  word-break: break-word;
}

.is-clickable {
  cursor: pointer !important;
}

.input-height {
  height: 40px !important;
}

.head-text {
  font-size: 1rem;
  font-weight: bold;
  text-align: center !important;
  color: white;
}

.content-flex-align-end {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 1rem;
}

//

//ESTANDAR BOTON
.principal-Button {
  font-size: 17px;
  color: variables.$white-base;
  width: 100%;
  height: 48px;
  padding: 13px 20px 13px 20px;
  box-shadow: none;
  outline: none;
  border: none;
}

.second-Button {
  font-size: 16px;
  width: 100%;
  height: 46px;
  padding: 13px 0px 13px 0px;
  box-shadow: none;
  outline: none;
  border: none;
}

//ESTANDAR CARDS
.principal-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 390px;
  height: 270px;
  border-radius: 5px;
  padding: 20px 15px 20px 15px;
}

.second-card {
  display: flex;
  flex-direction: column;
  width: 333px;
  height: 111px;
  padding: 20px;
  gap: 1rem;
  border-radius: 5px;
  opacity: 0px;
}

//FLEX COMPONENT
.content-flex-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}

.content-flex-row-between {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.content-flex-row-start {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: start;
  align-items: center;
}

.content-flex-end {
  display: flex;
  justify-content: end;
  gap: 1rem;
  width: 100%;
}

.content-flex-row {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.content-flex-cards {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1rem;
}

//GRID  COMPONENT
.content-grid-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.content-grid-fixed-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 1rem;
}

.content-grid-two {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
}

//CONTENT SCROLLBAR
.container-cards {
  display: flex;
  height: 40rem;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

//CONTENT MODAL
.container-modal {
  backdrop-filter: blur(15px);
  box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08);
}

.content-second-modal {
  display: flex;
  flex-direction: column;
  width: 448px;
  height: 281px;
  padding: 36px;
  gap: 16px;
}

//SELECT OPCIONS
.css-10a9lub-control {
  box-shadow: none;
}

.option-select {
  width: 100%;
}

//PADDING
.without-padding {
  padding: 0;
}
.withoutborder {
  border-radius: 0px;
}

.text-container {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

//MODAL PADDING
.modal-content-size {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 476.5px;
  padding: 40px 40px 40px 40px;
  overflow: auto;
}

.modal-content-padding {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 40px 40px;
  gap: 1rem;
  overflow: auto;
  z-index: 1;
  position: relative;
}

.full-width-input-search {
  width: 100%;
}

/*  5px - 820px */
@media only screen and (max-width: 820px) and (min-width: 5px) {
  .form-inputs {
    margin-right: 0;
    margin-left: 0;
  }
}

/* 700,s screen*/
@media only screen and (max-width: 780px) {
  .principal-Button {
    padding: 13px 10px 13px 10px;
  }
  .main-page-container {
    padding: calc(100px + 8px) 50px 30px 50px;
  }
}

/* 600,s screen*/
@media only screen and (max-width: 650px) {
  .main-page-container {
    padding: calc(90px + 8px) 30px 30px 10px;
  }
}

/* Medium screen*/
@media only screen and (max-width: 600px) {
  .principal-Button {
    height: 45px;
    font-size: 14px;
    padding: 13px 5px 13px 5px;
  }

  .principal-card {
    min-width: 100%;
    height: 300px;
  }

  .content-second-modal {
    width: 240px;
    padding: 10px;
  }

  .modal-content-size {
    width: 270px;
    padding: 10px;
  }

  .modal-content-padding {
    padding: 10px;
  }

  //MEDIUM / MOBILE SIZE
  h1 {
    font-size: 1rem;
    font-weight: bold;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.813em;
  }

  .main-page-container {
    padding: 5rem 0.5rem 3rem 0.5rem;
  }

  .main-container {
    margin-left: 0;
    transition: margin 0.3s linear;
    font-family: "PT Sans", sans-serif;

    .main-page-container {
      transition: padding 0.3s linear;
    }

    &.open {
      margin-left: auto;

      nav {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .content-grid-two {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* the most small screen*/
@media only screen and (max-width: 300px) {
  .main-page-container {
    padding: 5rem 0.2rem 2rem 0.2rem;
  }
}
