@use "../../styles/variables" as *;

.adjust-second-card {
  height: 156px;
}

.adjust-client-card-img {
  width: 20px;
  height: 70px;
}

.max-view-letter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
