@use "../../styles/variables" as *;

.conteiner-switch {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100px;
  .img {
    margin: auto;
    width: 22px;
    height: 15px;
  }

  .switch-button {
    box-shadow: 0px 2px 4px 0px $black-shadow inset;
    margin: auto;
    width: 46px;
    height: 20px;
    background-color: $light-mode-switch;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
  }

  .switch-handle {
    width: 16px;
    height: 16px;
    background-color: $hover-button-second;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(2px) translateY(-50%);
    transition: transform 0.3s ease;
  }

  // Darkmode
  .switch-button.active {
    background-color: $light-mode-switch;
  }

  .switch-button.active:hover {
    background-color: $hover-gray-light-mode !important;
  }

  .switch-button:hover {
    background-color: $hover-gray-light-mode;
  }

  .switch-button.active .switch-handle {
    background-color: $secondary-palette-dark-mode;
    transform: translateX(28px) translateY(-50%);
  }
}
