@use "../../styles/variables" as *;

.password-forgot {
  color: $white-base;
  display: flex;
  justify-content: start;
  flex-direction: column;
  background: rgba(6, 14, 27, 0.5);
  width: 90vw;
  max-width: 460px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  font-size: 1rem;
  gap: 1rem;
  height: 311px;

  .content-return {
    width: 1rem;
    cursor: pointer;
    display: flex;

    &-img {
      width: 1rem;
      height: 1rem;
    }
  }

  &-content-title {
    display: flex;
    justify-content: center;
    background-color: $primary-palette-dark-mode;
    border-radius: 0.313rem;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;

    .title-password {
      margin: 0.2rem 0.6rem 0.2rem 0.6rem;
      height: 45px;
      background-color: $accent-dark-mode;
      width: 100%;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (min-width: 400px) {
  .password-forgot {
    padding: 2rem 3rem 2rem 3rem;
  }
}

@media screen and (max-width: 399px) {
  .password-forgot {
    padding: 3rem 1rem 1rem 1rem;
  }
}
