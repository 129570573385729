@use "../../styles/variables" as *;

.confirmation-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 432px;
  height: 234px;
  padding: 39px 63px 39px 63px;
  gap: 23px;
}

@media only screen and (max-width: 500px) {
  .confirmation-modal {
    width: 270px;
    padding: 5px 5px 5px 5px;
  }
}
