$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 98%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(240, 100%, 100%);

$grey-font: #eaedf1;

$orange: hsl(14, 100%, 53%);
$yellow: hsl(48, 100%, 67%);
$green: hsl(141, 71%, 48%);
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(204, 86%, 53%);
$blue: hsl(217, 68%, 35%);
$purple: hsl(271, 100%, 71%);
$red: hsl(348, 100%, 61%);
$blue-med: #0c3c77;
$blue-med-faded: rgba(55, 87, 157, 0.5);
$blue-option: #040a1fc5;
$blue-l: hsl(210, 22%, 93%);

$primary: #01234c;
$secondary: #2b4a6f;
$background: #2b4a6f;
$text_primary: #b5e500;

$success: #7eb2c9;
$primary-palette: rgba(1, 35, 76);

//        Basic palets dark mode
$white-base: rgba(255, 255, 255, 1);
$white-palette: rgba(234, 237, 241);
$black-palette: rgba(0, 4, 9);
$alert-red: rgba(181, 41, 41, 1);
$alert-green: rgba(44, 167, 42, 1);
$secondary-palette: rgba(141, 143, 146);
$primary-palette-dark-mode: rgba(3, 7, 16, 1);
$secondary-palette-dark-mode: rgba(9, 25, 40, 1);
$tertiary-palette-dark-mode: rgba(23, 56, 89, 1);
$accent-dark-mode: rgba(57, 110, 156, 1);
$accent-second-dark-mode: rgba(191, 246, 244, 1);

//Degraded
$form-container-startAuth: rgba(6, 14, 27, 0.5);

//Shadow
$black-shadow: rgba(0, 0, 0, 0.25);
$black-second-shadow: rgba(0, 0, 0, 0.35);

//Home
$hover-home: rgba(22, 64, 100, 1);
$Active-home: rgba(20, 90, 144, 1);
$disabled-home: rgba(51, 69, 82, 1);

//Buttons
$hover-button: rgba(45, 84, 119, 1);
$Active-button: rgba(40, 72, 100, 1);
$disabled-button: rgba(232, 238, 246, 1);
$text-button-light: rgba(255, 255, 255, 1);
$grey-button-light: rgba(210, 210, 210, 1);

//Component
$hover-component: rgba(24, 84, 140, 1);
$Active-component: rgba(28, 105, 173, 1);
$disabled-component: rgba(228, 238, 250, 1);

//Electronic bill
$hover-button-second: rgba(245, 245, 245, 1);
$disabled-button-second: rgba(166, 165, 165, 1);

//input
$input-dark-focus: rgba(41, 66, 88, 1);
$border-input-focus: rgba(20, 77, 120, 0.5);

//placeholder
$placeholder-dark: rgba(255, 255, 255, 0.6);

//---------------------------------------------------//
//           Basic palets light mode
$primary-palette-light-mode: rgba(246, 249, 254, 1);
$tertiary-palette-light-mode: rgba(23, 56, 89, 1);
$accent-light-mode: rgba(57, 110, 156, 1);
$accent-second-light-mode: rgba(191, 246, 244, 1);
$accent-light-blue: rgb(107, 162, 209);

//Switch
$light-mode-switch: rgba(152, 152, 152, 1);

//Hovers
$hover-gray-light-mode: rgb(119, 118, 118);

//Border
$border-dark-blue: rgba(9, 25, 40, 0.5);
$border-light-accent: rgba(42, 141, 233, 1);
$border-black: rgb(7, 7, 7);

//Select
$select-primary-blue: rgba(9, 25, 40, 0.8);

//Card
$primary-card-light: rgba(200, 207, 214, 1);
$blue-card-options: rgba(68, 129, 182, 0.5);
$menu-options: rgba(246, 249, 254, 0.8);
$menu-select: rgba(9, 25, 40, 0.6);

//input
$principal-light-input: rgba(230, 234, 239, 1);
$border-bottom-blue: rgba(18, 59, 100, 1);
$input-light-focus: rgba(199, 221, 240, 0.5);
$border-light-focus: rgba(127, 159, 185, 0.6);
$input-light-active: rgba(56, 133, 191, 1);
