@use "../../styles/variables" as *;

.option-container {
  display: flex;
}

.option-content-left {
  justify-content: flex-start;
  text-align: flex-start;
  width: 90%;
}
