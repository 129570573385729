@use "../../styles/variables" as variables;

.imgProduct {
  width: 92px;
  height: 95px;
}
.one-line {
  white-space: nowrap;
}
.ellipsis {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
}
