@use "../../styles/variables" as *;

.background-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: url(../../assets/login-background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  background-size: cover;
  height: fit-content;
  padding: 4rem 0 4rem 0;
  min-height: 100vh;
}

.landing-page {
  width: 100%;
  align-items: center;
  position: relative;
  overflow-y: overlay;
  line-height: 1.5;
  margin: auto;

  &-title {
    color: #ffffff;
    text-align: center;
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: center;

    .input,
    select {
      border: 0px;
      gap: 10px;
      border-radius: 5px;
      opacity: 0px;
      box-shadow: 3px 4px 9px 0px $black-second-shadow inset;
      font-size: 0.9rem;
      height: 46px;

      option {
        background: $select-primary-blue;
        border: 1px solid $border-dark-blue;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    border-radius: 0;
    color: $white-base;
  }

  &-button {
    color: $white-base;

    &-secondary {
      background-color: $white-palette;
      color: $secondary;
      border: 0px;
      cursor: pointer;
    }

    &-dark {
      background-color: $secondary-palette-dark-mode;
      color: $white-palette;
      border: 0px;
      font-size: 1rem;
      height: 45px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        color: $white-palette;
        background: $hover-home;
      }
    }

    &-light {
      background-color: $accent-light-mode;
      color: $white-palette;
      border: 0px;
      font-size: 1rem;
      height: 45px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: $hover-button;
        color: $white-palette;
      }

      &:focus {
        background: $Active-button;
        color: $white-palette;
      }

      &:disabled {
        background: $disabled-button;
      }
    }
  }

  &-footer {
    margin-top: 1rem;

    p {
      text-align: center;
    }
  }

  &-google {
    width: 4rem;
    height: 4rem;
    border: none;

    img {
      max-height: 3rem;
      max-width: 3rem;
    }
  }

  &-img {
    color: $white-palette;
    max-width: 2rem;
    max-height: 2rem;
  }

  .accept-terms {
    .control {
      width: 100%;
      margin-right: 0.7%;
    }

    input,
    select,
    textarea {
      border: 0.1rem solid #b4c8e0;
    }

    .label {
      width: 10%;
    }
  }

  ::placeholder {
    color: white;
  }

  .label {
    font-size: 15px;
    font-weight: 400;
    align-content: center;
    width: 7%;
  }
}

.btn-white {
  background-color: #fff;
  color: #777;

  &::after {
    background-color: #fff;
  }
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animate {
  animation: moveInBottom 1s ease-out;
  animation-fill-mode: backwards;
  background-color: transparent;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (min-width: 821px) {
  .text {
    font-size: 15px;
    font-weight: 400;
    align-content: center;
  }
}

/* Para 320px */
@media only screen and (max-width: 340px) and (min-width: 5px) {
  .text {
    font-size: 10px;
    font-weight: 400;
    align-content: center;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
