@use "../../styles/variables" as *;
.main-select {
  height: 4.875rem;
}

.selector {
  background-color: transparent;
  max-width: 100%;
  border: 0px;
  background-color: unset;
  border-bottom: 1px solid;
  font-size: 1rem;
  border-radius: 0px;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $white-base;
  right: 1.125em;
  top: 1.6em;
  z-index: 4;
}

.label-title {
  margin: 0;
  color: $white-base;
}
