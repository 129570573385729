.content-product {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.SearchProduct {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
}

.headerButton {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: row;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;

  .searchInput {
    min-width: 31rem;
    max-width: 31rem;
  }

  img {
    width: 20px;
    height: 20px;
  }

  .search-icon {
    position: absolute;
    right: 10px;
  }
}

.textSearch {
  font-size: 18px;
}

.adjust-width-button {
  max-width: 11rem;
}

//SCREEN

//MOBILE
@media screen and (max-width: 500px) {
  .headerButton {
    flex-direction: column;
  }
}

//TABLET
@media screen and (max-width: 660px) {
  .headerButton {
    justify-content: center;
  }
  .input-container .searchInput {
    min-width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .SearchProduct {
    justify-content: center;
    height: 35rem;
  }
}
